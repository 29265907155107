<template>
  <div>
    <b-button @click="handleClick" variant="danger" class="delete">
      <i class="fal fa-trash-alt"></i>
    </b-button>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleClick() {
      Swal.fire({
        title: this.$t("delete_confirm.are_you_sure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("delete_confirm.cancel"),
        confirmButtonText: this.$t("delete_confirm.confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("clicktodelete");
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .delete {
  background: #f02849;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
</style>