<template>
  <div class="contact_management" style="padding: 5px 10px 10px 10px">
    <ContactInfo class="mb-3" :getContactInfo="getContactInfo" />
    <div v-if="!isLoading">
      <table>
        <tr>
          <th>{{ $t("add_contact_info.url") }}</th>
          <th>{{ $t("admin.email") }}</th>
          <th>{{ $t("admin.email_icon") }}</th>
          <th>{{ $t("add_contact_info.address") }}</th>
          <th>{{ $t("add_contact_info.phone_number") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr>
          <td>
            <hooper
              v-if="allContactInfo.follow != 'null'"
              :progress="true"
              :autoPlay="true"
              :playSpeed="3000"
              :hover="true"
              style="border-radius: 0px"
            >
              <slide v-for="item in allContactInfo.follow" :key="item._id">
                <div class="conatiner" style="height: 100px; width: 150px">
                  <a :href="item.url" target="_blank">
                    <img
                      :src="`${server_url}/${item.image}`"
                      alt=""
                      class="image"
                      style="height: 100px; width: 150px"
                    />
                  </a>
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </td>
          <td>
            <div v-for="(item, index) in allContactInfo.email" :key="index">
              {{ item }}
            </div>
          </td>
          <td>
            <div v-if="allContactInfo.emailIcon != null">
              <img
                :src="`${server_url}/${allContactInfo.emailIcon}`"
                alt=""
                class="image"
                style="height: 100px; width: 150px"
              />
            </div>
          </td>
          <td>
            <span v-if="lang == 'ka'">{{ allContactInfo.addressKA }}</span>
            <span v-else>{{ allContactInfo.addressEN }}</span>
          </td>
          <td>
            {{ allContactInfo.phoneNumber }}
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editContactInfo(
                    allContactInfo._id,
                    allContactInfo.email,
                    allContactInfo.emailIcon,
                    allContactInfo.addressKA,
                    allContactInfo.addressEN,
                    allContactInfo.phoneNumber,
                    allContactInfo.follow
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="9"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal
      id="modal-lg-2"
      v-model="modalShow"
      size="lg"
      hide-footer
      v-if="edit"
    >
      <div class="update">
        <div class="text_editor" v-if="update.email != 0">
          <label for="">{{ $t("admin.add_email") }}</label>
          <div
            v-for="(item, index) in update.email"
            :key="index"
            class="d-flex"
          >
            <div class="choosed_keywords">
              {{ item }}
            </div>
            <i
              class="fal fa-times-square"
              style="cursor: pointer; margin-left: 20px; font-size: 20px"
              @click="delete_keywords(index)"
            ></i>
          </div>
        </div>
        <div class="text_editor add_comp">
          <label style="margin-right: 10px" for="">{{
            $t("admin.email")
          }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              style="margin-right: 10px"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square" @click="delete_comp"></i>
          </div>
        </div>
        <div>
          <label for="">{{ $t("admin.email_icon") }}</label>
          <img
            style="width: 150px; height: 100px"
            :src="`${server_url}/${update.emailIcon}`"
            alt="await for new image to upload"
            class="image"
          />
        </div>
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          ref="image"
          @change="previewFiles"
        />
        <div class="text_editor">
          <label for="">{{ $t("add_contact_info.address") }} (KA)</label>
          <b-form-input v-model="update.addressKA"></b-form-input>
          <label for="">{{ $t("add_contact_info.address") }} (EN)</label>
          <b-form-input v-model="update.addressEN"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_contact_info.phone_number") }}</label>
          <b-form-input v-model="update.phoneNumber"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_contact_info.url") }}</label>
          <div v-for="item in follow" :key="item._id">
            <b-form-input
              v-model="item.url"
              @change="editLink(item._id, item.url, item.image)"
            ></b-form-input>
          </div>
        </div>
        <hooper class="hooper_edit" :itemsToShow="3" :itemsToSlide="3">
          <slide style="width: 160px" v-for="item in follow" :key="item._id">
            <div class="upload_image">
              <a :href="item.url" target="_blank">
                <img
                  :src="`${server_url}/${item.image}`"
                  alt=""
                  class="image"
                  style="height: 100px; width: 150px"
                />
              </a>
              <div class="overlay">
                <div class="icon d-flex">
                  <input
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    multiple
                    id="file"
                    class="hide_choose_file"
                    @change="editImage($event, item._id, item.url)"
                  />
                  <i
                    class="fal fa-trash"
                    @click="
                      deleteImage(
                        item._id,
                        update.email,
                        update.emailIcon,
                        update.addressKA,
                        update.addressEN,
                        update.phoneNumber
                      )
                    "
                  ></i>
                </div>
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
        <button
          type="submit"
          class="submit mt-3 btn btn-primary"
          @click="UpdateContactInfo()"
        >
          <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import ContactInfo from "../AddComponents/AddContactInfo.vue";
import Swal from "sweetalert2";

export default {
  name: "getTeam",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    VueEditor,
    spinner,
    ConfirmDelete,
    ContactInfo,
  },
  data() {
    return {
      modalShow: false,
      allContactInfo: [],
      edit: false,
      active_id: "",
      image_id: "",
      update: {
        addressEN: "",
        addressKA: "",
        phoneNumber: "",
        emailIcon: null,
        email: [],
      },
      components: [{ comp: "comp" }],
      follow_image: null,
      follow_url: null,
      isLoading: false,
      server_url: env.server_url,
      lang: "ka",
    };
  },
  mounted() {
    this.getContactInfo();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getContactInfo() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/informations/contactInformation`)
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.active_id = res.data.item._id;
          this.allContactInfo = res.data.item;
        });
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.update.email.splice(index, 1);
      }
    },
    get_value(e) {
      this.update.email.push(e.target.value);
    },
    editLink(id, url, image) {
      this.image_id = id;
      this.follow_url = url;
      this.follow_image = image;
    },
    editImage(e, id, url) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.image_id = id;
      this.follow_url = url;
      this.createBase64Image(image);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.follow_image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64EmailIcon(image);
    },
    createBase64EmailIcon(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.emailIcon = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    editContactInfo(
      id,
      email,
      emailIcon,
      addressKA,
      addressEN,
      phoneNumber,
      follow
    ) {
      this.edit = true;
      this.active_id = id;
      this.update.email = email;
      this.update.emailIcon = emailIcon;
      this.update.addressEN = addressEN;
      this.update.addressKA = addressKA;
      this.update.phoneNumber = phoneNumber;
      this.follow = follow;
    },
    UpdateContactInfo() {
      this.isLoading = true;
      if (this.image_id !== "") {
        axios
          .post(
            `${env.host}/change/contactInformation/follow/${this.image_id}`,
            {
              image: this.follow_image,
              url: this.follow_url,
            }
          )
          .then(() => {
            Swal.fire({
              icon: "success",
              text: this.$t("success.success_edit"),
            });
            this.isLoading = false;
            this.modalShow = false;
            setTimeout(() => {
              this.getContactInfo();
            }, 1000);
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
      }

      axios
        .post(`${env.host}/change/contactInformation`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getContactInfo();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    deleteImage(id, email, emailIcon, addressKA, addressEN, phoneNumber) {
      this.isLoading = true;
      console.log(id);
      axios
        .delete(`${env.host}/delete/contactInformation/follow/${id}`)
        .then(() => {
          axios
            .get(`${env.host}/get/informations/contactInformation`)
            .then((res) => {
              this.editContactInfo(
                this.active_id,
                email,
                emailIcon,
                addressKA,
                addressEN,
                phoneNumber,
                res.data.item.follow
              );
              this.isLoading = false;
            });
        });
    },
  },
};
</script>

<style scoped>
.contact_management {
  width: 100%;
}
.hooper_edit {
  background: #f2f5fa;
  padding: 10px;
  width: 100% !important;
  height: 150px !important;
}
input#file {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 40px 0 0 0;
  height: 10px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-size: 30px 30px;
}
.upload_image {
  position: relative;
}
.image {
  display: block;
  width: 150px;
  height: 100px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 150px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #7e5493;
}
.upload_image:hover .overlay {
  opacity: 1;
}
.icon {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.icon i {
  cursor: pointer;
  margin-left: 5px;
  padding: 10px;
  background: #e4e4e4;
  border-radius: 5px;
  color: #157efb;
  transition: 0.2s;
}
.icon i:hover {
  transition: 0.2s;
  color: #157efb;
}
.hooper {
  width: 150px;
  height: 100px;
}
.user_management {
  width: 100%;
  overflow: hidden;
}
/* .testingScroll {
  width: 100% !important;
  max-width: 1400px;
  min-height: calc(100vh - 160px);

  max-height: calc(100vh - 200px) !important;
  overflow-x: scroll;
  overflow-y: scroll;
} */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

label {
  font-family: mtavruliBOLD;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
:v-deep .btn-danger {
  border-radius: 0px !important;
}
.delete {
  background: #f02849;
  border-radius: 0px !important;
}
i {
  cursor: pointer;
}
label {
  margin-bottom: 10px;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
input,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  padding-left: 10px;
  height: 40px;
  margin-bottom: 8px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
