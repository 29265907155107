<template>
  <div class="contact_info">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_contact_info.add_contact_info")
    }}</b-button>
    <b-modal id="modal-lg" v-model="modalShow" size="lg" hide-footer>
      <form @submit.prevent="addContactInfo()">
        <div class="text_editor">
          <label for="">{{ $t("add_contact_info.url") }}</label>
          <b-form-input v-model="form.url"></b-form-input>
        </div>
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          ref="image"
          @change="previewFiles"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        image: null,
        url: "",
      },
      isLoading: false,
    };
  },
  props: {
    getContactInfo: { type: Function },
  },
  methods: {
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image(image);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    addContactInfo() {
      this.isLoading = true;
      axios
        .post(`${env.host}/create/contactInformation/follow`, this.form)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getContactInfo();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.form.image = null;
      this.form.url = "";
    },
  },
};
</script>

<style scoped>
.contact_info {
  width: 100% !important;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
